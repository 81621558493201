import React from "react";
import CustomAccordion from "shared/Accordion/Accordion";
import "./Background.css";
import AiBanner from "assets/aisg.png";

const ChallengeInformation = () => {
  return (
    <div>
      <div>
        <div className="banner-overlay">
          <div className="banner-text">
            {/* FYI, div className css file is found in AppNavigator.css */}
            <h1>Online Safety Prize Challenge - Competition Platform</h1>
            <p
              style={{
                fontFamily: "Atkinson Hyperlegible",
                fontSize: "2rem",
                marginTop: "20px",
              }}
            >
              <b>Low-Resource Detection of Harmful Memes with Social Bias</b>
            </p>
          </div>
        </div>

        <img
          style={{
            width: "100%",
            height: "15.625rem",
          }}
          src={AiBanner}
          alt="aibanner"
        ></img>
      </div>
      <div data-testid="challenge-info-wrapper" className="wrapper">
        <div data-testid="the-title" className="the-title">
          Challenge Information
        </div>

        {/* 1. ABSTRACT */}
        <CustomAccordion
          testId="challenge-info-wrapper-abstract"
          title="Abstract"
        >
          <p>
            Artificial intelligence (AI) and machine learning play a crucial
            role in addressing the pervasive issue of harmful content on social
            media. In multicultural cities like Singapore, the complexity of
            detecting harmful online content arises from the sheer diversity of
            social contexts, languages, colloquialisms, and experiences. This
            Online Safety Prize Challenge (“Challenge”) aims to advance research
            in developing multimodal, multi-lingual, zero-shot models that
            distinguish between benign and harmful memes relevant to the
            Singaporean context. By doing so, AI Singapore (AISG) hopes to spur
            advanced technology development to tackle harmful memes and
            encourage safer online interactions in Singapore and globally.
          </p>
        </CustomAccordion>

        {/* 2. SENSITIVE CONTENT WARNING */}
        <CustomAccordion
          testId="challenge-info-wrapper-sensitive-content-warning"
          title="Sensitive Content Warning"
        >
          <p>
            This page contains materials that some audiences may find harmful or
            offensive. Including such content is essential for the Challenge's
            objectives and does not signify endorsement or promotion of harmful
            sentiments. The content is strictly for informational and
            educational purposes. Readers are advised to approach with
            discretion and understand the context of its presentation within
            this Challenge.
          </p>
        </CustomAccordion>

        {/* 3. LIMITATION OF LIABILITY */}
        <CustomAccordion
          testId="challenge-info-wrapper-limitation-of-liability"
          title="Limitation of Liability"
        >
          <p>
            The content provided on this page does not represent the views,
            beliefs, or opinions of AISG. They are included solely for this
            Challenge and to further the goal of eradicating this type of online
            content. AISG shall not be held responsible for any harm, distress,
            or offence taken by participants or any third parties due to content
            provided in this booklet. Participants shall enter and engage in the
            Challenge at their own risk.
          </p>
        </CustomAccordion>

        {/* 4. TABLE OF CONTENTS */}
        <CustomAccordion
          testId="challenge-info-wrapper-table-of-contents"
          title="Table of Contents"
        >
          <ul>
            <li>
              <a href="/background" className="url-text-styling">
                Background
              </a>
            </li>
            <li>
              <a href="/challenge-structure" className="url-text-styling">
                Summary of Challenge Structure
              </a>
            </li>
            <li>
              <a href="/technical-details" className="url-text-styling">
                Challenge Rules & Technical Details
              </a>
            </li>
            <li>
              <a href="/supplementary-information" className="url-text-styling">
                Supplementary Information
              </a>
            </li>
          </ul>
        </CustomAccordion>
      </div>
    </div>
  );
};

export default ChallengeInformation;
