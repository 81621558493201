import React from "react";
import CustomAccordion from "../../shared/Accordion/Accordion";
import "./Background.css";

const SupplementaryInformation = () => {
  return (
    <div data-testid="terms-and-condition-wrapper" className="wrapper">
      {/* <h1>
        <mark>PLACEHOLDER PAGE IN CASE THERE'S A TNC PAGE</mark>
      </h1> */}
      <div>
        <div data-testid="the-title" className="the-title">
          Supplementary Information{" "}
        </div>
        <p
          style={{
            margin: "0.25em 1.5em",
            fontFamily: "Atkinson Hyperlegible",
            textAlign: "center",
          }}
        >
          This section aims to provide participants with a brief understanding
          of the nature and scope of online harm primarily encountered in
          Singaporean environments.
        </p>
      </div>

      <CustomAccordion
        testId="supplementary-info-origins-and-sources"
        title="Origins and Sources"
      >
        <p>
          Harmful content may originate both domestically and internationally.
          Within Singapore, such content may often emerge from various
          sub-communities, reflecting internal disputes, misunderstandings, or
          rivalries. This content typically distorts, mocks, or misinterprets
          the nation's distinct societal nuances or positions on various issues.
          Conversely, international content might be less specific in its
          origin, often stemming from ambiguous or generalised sources. These
          sources might exploit stereotypes, spread misinformation, leverage
          cultural misunderstandings, and antagonise or discredit individuals
          and groups.{" "}
        </p>
        <p>
          A wide array of platforms serves as outlets for these harmful
          expressions. The most direct mediums are social media platforms and
          digital forums where communities gather to share opinions. Some
          polarized sites that harbor extreme viewpoints may seed, or even
          encourage the rapid proliferation of harmful content. Participants are
          encouraged to delve deeper into these memes’ points of origin and
          distribution channels. Such investigative efforts will be beneficial
          and provide participants with additional data sources that can
          significantly enhance the model development process.
        </p>
        <p>
          To provide participants with a starting point to begin their search,
          Table 1 showcases a few Singaporean social media websites for
          consideration. These sites may not necessarily contain harmful content
          but serve as congregation points for many communities in Singapore and
          can provide participants with valuable background information about
          the types of humour and communication styles frequently found in
          Singapore.{" "}
        </p>
        <table style={{ width: "100%" }}>
          <tr>
            <td>
              <a
                href="https://www.reddit.com/r/singapore/"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                Singapore Subreddit
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://www.instagram.com/sgagsg/"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                SGAG
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://www.facebook.com/SUTDmemes/"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                A Better World By Memes
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://www.instagram.com/wakeupsingapore/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                Wake Up, Singapore
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://www.instagram.com/memedefsg/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                Memedef.sg
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="https://www.facebook.com/groups/827712277859634/"
                target="_blank"
                rel="noopener noreferrer"
                className="url-text-styling"
              >
                Umbrage Singapore
              </a>{" "}
            </td>
          </tr>
        </table>
        <p>
          <i>
            Table 1 - Table of Singaporean online communities where memes are
            often shared.
          </i>
        </p>
      </CustomAccordion>

      <CustomAccordion
        testId="supplementary-info-forms-and-subject-domains"
        title="Forms and Subject Domains"
      >
        <p>
          Hate speech and harmful content often centre on divisive and
          contentious subject matters. Typical areas include:
        </p>
        <ul>
          <li>Racial disparities</li>
          <li>Religious beliefs and practices</li>

          <li>Sexual orientation</li>
          <li>Nationalistic sentiments and xenophobia against migrants</li>
          <li>Socio-economic divides and class struggles</li>
          <li>Age-related biases</li>
          <li>Gender discrimination</li>
          <li>Discrimination based on illnesses and disabilities</li>
        </ul>

        <p>
          Given Singapore's multi-lingual landscape and connection with the
          broader Southeast Asian region, harmful content can manifest in
          several languages. For this Challenge, the primary languages of
          interest are Singapore's commonly used languages: English, Mandarin,
          Malay, and Tamil. Additionally, the colloquial blend known as
          "Singlish"—an amalgamation of English with elements from other
          languages over time—is a significant mode of online expression.
          Effective models should detect harmful memes and discern nuances
          across these languages and their unique ways of conveying derogatory
          sentiments.
        </p>
      </CustomAccordion>
    </div>
  );
};

export default SupplementaryInformation;
