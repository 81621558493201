import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import axios from "axios";
import axiosAuth from "utils/axiosAuth";
// import axios from "utils/axios";
import { backendDomain } from "utils/envConfig";

import toast from "react-hot-toast";

const initialState = {
  getPrivateLeaderboardM1Loading: false,
  getPrivateLeaderboardM1Data: [],
  isPrivateLeaderboardM1Created: false,

  getPrivateLeaderboardM2Loading: false,
  getPrivateLeaderboardM2Data: [],
  isPrivateLeaderboardM2Created: false,

  getLeaderboardLoading: false,
  getLeaderboardData: [],
  isLeaderboardCreated: false,

  getCurrentTeamRankingLoading: false,
  getCurrentTeamRankingData: [],
  isGetCurrentTeamRankingCreated: false,

  privateLeaderboardData: [],
  getPrivateLeaderboardStatus: null,
  getPrivateLeaderboardHasError: false,
  getPrivateLeaderboardErrors: [],
  getPrivateLeaderboardIsNotAdminError: false,
};
const name = "leaderboardState";

const api = axios.create({
  baseURL: `${backendDomain}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

// get private leaderboard mode 1
export const getPrivateLeaderboardMode1 = createAsyncThunk(
  `${name}/api/v0.1/result/private-leaderboard?mode=1`,
  async () => {
    try {
      const res = await api.get("/api/v0.1/result/private-leaderboard?mode=1");

      if (res.status >= 200 && res.status < 300) {
        // if response status code is 200+
        return res.data;
      }
    } catch (err) {
      toast.error("Error retrieving private leaderboard");

      throw new Error(err.message); // throws error for builder to catch
    }
  }
);

// get private leaderboard Mode 2
export const getPrivateLeaderboardMode2 = createAsyncThunk(
  `${name}/api/v0.1/result/private-leaderboard?mode=2`,
  async () => {
    try {
      const res = await api.get("/api/v0.1/result/private-leaderboard?mode=2");

      if (res.status >= 200 && res.status < 300) {
        // if response status code is 200+
        return res.data;
      }
    } catch (err) {
      toast.error("Error retrieving private leaderboard");

      throw new Error(err.message); // throws error for builder to catch
    }
  }
);

// get public leaderboard
export const getLeaderboard = createAsyncThunk(
  `${name}/api/v0.1/result/leaderboard`,
  async () => {
    try {
      let data = "";

      const res = await axios.request({
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/result/leaderboard`,
        headers: {
          Accept: "application/json",
        },
        data: data,
      });

      if (res.status >= 200 && res.status < 300) {
        // if response status code is 200+
        return res.data;
      }
    } catch (err) {
      toast.error("Error retrieving leaderboard");

      throw new Error(err.message); // throws error for builder to catch
    }
  }
);

//get current team ranking
export const getCurrentTeamRanking = createAsyncThunk(
  `${name}/api/v0.1/result/leaderboard_ranking`,
  async () => {
    try {
      let data = "";

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${backendDomain}/api/v0.1/result/leaderboard_ranking`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        data: data,
      };
      const res = await axios.request(config);

      return res.data;
    } catch (err) {}
  }
);

// get private leaderboard
export const getPrivateLeaderboard = createAsyncThunk(
  `${name}/api/v0.1/result/private-leaderboard`,
  async () => {
    try {
      const res = await axiosAuth.get("api/v0.1/result/private-leaderboard");
      if (res.status >= 200 && res.status < 300) {
        // if response status code is 200+
        return res.data;
      }
    } catch (err) {
      toast.error("Error retrieving admin leaderboard");

      throw new Error(err.message); // throws error for builder to catch
    }
  }
);

const leaderboardSlice = createSlice({
  name,
  initialState,
  reducers: {
    completeGetLeaderboardData: (state) => {
      state.getLeaderboardData = initialState.getLeaderboardData;
    },

    completeGetCurrentTeamRankingData: (state) => {
      state.getCurrentTeamRankingData = initialState.getCurrentTeamRankingData;
    },

    completeGetPrivateLeaderboard: (state) => {
      state.getCurrentTeamRankingData = initialState.getCurrentTeamRankingData;
      state.privateLeaderboardData = initialState.privateLeaderboardData;
      state.getPrivateLeaderboardStatus =
        initialState.getPrivateLeaderboardStatus;
      state.getPrivateLeaderboardHasError =
        initialState.getPrivateLeaderboardHasError;
      state.getPrivateLeaderboardErrors =
        initialState.getPrivateLeaderboardHasErrors;
    },
  },

  extraReducers: (builder) => {
    // handle get private leaderboard mode 1
    builder.addCase(
      getPrivateLeaderboardMode1.fulfilled,
      (state, { payload }) => {
        state.getPrivateLeaderboardM1Data = payload;

        if (payload) {
          state.isPrivateLeaderboardM1Created = true;
        }

        state.getPrivateLeaderboardM1Loading = false;
      }
    );
    builder.addCase(getPrivateLeaderboardMode1.pending, (state) => {
      state.getPrivateLeaderboardM1Loading = true;
    });
    builder.addCase(getPrivateLeaderboardMode1.rejected, (state) => {
      state.getPrivateLeaderboardM1Loading = false;
    });

    // handle get private leaderboard mode 2
    builder.addCase(
      getPrivateLeaderboardMode2.fulfilled,
      (state, { payload }) => {
        state.getPrivateLeaderboardM2Data = payload;

        if (payload) {
          state.isPrivateLeaderboardM2Created = true;
        }

        state.getPrivateLeaderboardM2Loading = false;
      }
    );
    builder.addCase(getPrivateLeaderboardMode2.pending, (state) => {
      state.getPrivateLeaderboardM2Loading = true;
    });
    builder.addCase(getPrivateLeaderboardMode2.rejected, (state) => {
      state.getPrivateLeaderboardM2Loading = false;
    });

    // handle get leaderboard
    builder.addCase(getLeaderboard.fulfilled, (state, { payload }) => {
      state.getLeaderboardData = payload;

      if (payload) {
        state.isLeaderboardCreated = true;
      }

      state.getLeaderboardLoading = false;
    });
    builder.addCase(getLeaderboard.pending, (state) => {
      state.getLeaderboardLoading = true;
    });
    builder.addCase(getLeaderboard.rejected, (state) => {
      state.getLeaderboardLoading = false;
    });

    // handle get current team ranking
    builder.addCase(getCurrentTeamRanking.fulfilled, (state, { payload }) => {
      state.getCurrentTeamRankingData = payload;

      if (payload) {
        state.isGetCurrentTeamRankingCreated = true;
      }

      state.getCurrentTeamRankingLoading = false;
    });
    builder.addCase(getCurrentTeamRanking.pending, (state) => {
      state.getCurrentTeamRankingLoading = true;
    });
    builder.addCase(getCurrentTeamRanking.rejected, (state) => {
      state.getCurrentTeamRankingLoading = false;
    });

    // handle get private leaderboard
    builder.addCase(getPrivateLeaderboard.fulfilled, (state, { payload }) => {
      state.getPrivateLeaderboardStatus = "fulfilled";
      state.privateLeaderboardData = payload.results;
      state.getSubmitResultsHasError = false;
      state.getPrivateLeaderboardErrors = [];
    });
    builder.addCase(getPrivateLeaderboard.pending, (state) => {
      state.getPrivateLeaderboardStatus = "pending";

      state.getLeaderboardLoading = true;
    });
    builder.addCase(getPrivateLeaderboard.rejected, (state, action) => {
      state.getPrivateLeaderboardStatus = "rejected";
      state.getSubmitResultsHasError = true;
      state.getPrivateLeaderboardErrors.push(action.error.message);

      if (action.error.message == "TMGT-110028") {
        // if user is not admin error, set state accordingly
        state.getPrivateLeaderboardIsNotAdminError = true;
      }
    });
  },
});

export const { completeGetLeaderboard } = leaderboardSlice.actions;
export const { completeGetCurrentTeamRankingData } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;

export const useGetPrivateLeaderboardM2Data = () =>
  useSelector((state) => state.leaderboardState.getPrivateLeaderboardM2Data);

export const useGetPrivateLeaderboardM1Data = () =>
  useSelector((state) => state.leaderboardState.getPrivateLeaderboardM1Data);

export const useGetLeaderboardData = () =>
  useSelector((state) => state.leaderboardState.getLeaderboardData);

export const useGetCurrentTeamRankingData = () =>
  useSelector((state) => state.leaderboardState.getCurrentTeamRankingData);

// admin private leaderboard selectors
export const usePrivateLeaderboardData = () =>
  useSelector((state) => state.leaderboardState.privateLeaderboardData);

export const useGetPrivateLeaderboardStatus = () =>
  useSelector((state) => state.leaderboardState.getPrivateLeaderboardStatus);

export const useGetPrivateLeaderboardHasError = () =>
  useSelector((state) => state.leaderboardState.getPrivateLeaderboardHasError);

export const useGetPrivateLeaderboardErrors = () =>
  useSelector((state) => state.leaderboardState.getPrivateLeaderboardErrors);

export const useGetPrivateLeaderboardIsNotAdminError = () =>
  useSelector(
    (state) => state.leaderboardState.getPrivateLeaderboardIsNotAdminError
  );
