import React from "react";
import "./Background.css";
import CustomAccordion from "shared/Accordion/Accordion";

import fig1_typology_of_harmful_memes from "assets/Faq/fig1_typology_of_harmful_memes.png";
import fig2_the_spectrum_of_approaches from "assets/Faq/fig2_the_spectrum_of_approaches.png";

// test img import

const Background = () => {
  return (
    <div>
      

      <div data-testid="wrapper" className="wrapper">
        {/* PAGE TITLE  */}
        <div data-testid="the-title" className="the-title">
          Background
        </div>

        {/* 1. INTRODUCTION  */}
        <CustomAccordion testId="challenge-title" title="Introduction">
          <p>
            In an increasingly interconnected digital world, the spread of
            harmful content, such as hate speech, threatens communities and
            societal bonds. Historically dominated by textual content, the
            advent of social media and content-sharing platforms has ushered in
            diverse harmful content formats, with memes—a fusion of images and
            text designed to convey messages—at the forefront. Traditional
            automated hate speech detection systems, tailored primarily for
            text, now grapple with these evolving threats. Meta’s recent Hateful
            Memes Challenge underscores the urgency to recalibrate and address
            hate within multi-modal memes (1). With the rampant spread of such
            harmful memes, there is a pressing need for advanced systems that
            can identify and combat hate in all its complexities.
          </p>
          <p>
            Creating safe online environments by combating hate, prejudice, and
            discrimination is paramount. This is pertinent in Singapore,
            renowned for its racial and religious diversity (2). Preserving this
            harmony is crucial for the country's sustained social cohesion.
            Contentious issues about current affairs such as emerging
            socio-economic challenges, discourse on immigration and employment,
            and polarising perspectives on sensitive matters such as LGBTQ+ can
            result in the proliferation of various forms of harmful content
            online. If left unchecked, this content could amplify tensions and
            potentially destabilise and harm governance, communities, and
            individuals (3).
          </p>
          <p>
            Detecting harmful memes presents inherent challenges due to their
            reliance on contextual knowledge. Most multimodal models were
            trained on memes with a predominantly Western context (1). Although
            some forms of discrimination are global, understanding local nuances
            and terminology is essential. For instance, in Singapore, Singlish—a
            Creole language combining English with elements of Chinese, Malay,
            and Tamil—adds layers of complexity. Singlish phrases could carry
            specific cultural connotations that could be misunderstood or
            misinterpreted by AI models not familiar with Singaporean culture.
            This cultural tapestry extends beyond language, encompassing unique
            societal norms, humour styles, and references to local media and
            events.
          </p>
        </CustomAccordion>

        {/* 2. RELATED WORK  */}
        <CustomAccordion testId="challenge-related-work" title="Related Work">
          {/* <div className="figure-two">
            <img src={sharma} alt="figure2"></img>
          </div> */}

          <p>
            Sharma et al. proposed a new typology of harmful memes as shown in
            Figure 1, and found that many types of memes such as self-harm and
            extremism are understudied due to insufficient relevant datasets
            (4). The authors also highlighted that most existing datasets that
            capture multi-class scenarios do not encompass the full range of
            emotional spectra that memes can convey. Additionally, memes can be
            spread worldwide, be multilingual and incorporate different
            cultures.
          </p>

          <p>
            Internet memes often combines multiple modalities. In the TotalDef
            Meme research paper, for instance, the authors trained VisualBERT
            and fine-tuned CLIP using the memes’ text and visuals (5). In Meta’s
            Hateful Memes Challenge one of the winners used a pre-trained
            VisualBERT, fine-tuned on an expanded train dataset, and applied
            Majority Voting over the 27 best models (6).
          </p>

          <div className="figure-two">
            <img
              src={fig1_typology_of_harmful_memes}
              alt="figure1"
              className="fig-img-size"
            ></img>
          </div>

          <p>
            Recent studies have introduced various methods that extend from
            Large Language Models (LLM) to multimodal settings. One such review
            by Google Research, discussed different approaches to achieving
            multimodal capabilities in LLM for medical applications. These
            approaches include tool use, model grafting, and generalist systems.
            Tool use outsources data analysis from various modalities to
            specialised software subsystems or "tools” while model grafting
            integrates domain-specific models into the LLM. The generalist
            systems approach shown in Figure 2 aims to create an integrated AI
            system capable of processing diverse modalities (7).
          </p>

          <p>
            Since comprehension of memes requires complex reasoning and
            contextual background reasoning, Cao et al. proposed a prompt-based
            model, whereby simple prompts were constructed alongside in-context
            examples to exploit the implicit knowledge in the pre-trained
            RoBERTa language model for hateful meme classification (8). Images
            were deconstructed into text captions, and the text captions and
            extracted text from the meme were input into the language model to
            generate the classification output.
          </p>

          <div className="figure-two">
            <img
              className="fig-img-size"
              src={fig2_the_spectrum_of_approaches}
              alt="fig2-llm-legend"
            ></img>
          </div>

          <p></p>

        </CustomAccordion>

        {/* 3. TASK FORMULATION */}
        <CustomAccordion
          testId="challenge-task-formulation"
          title="Task Formulation"
        >
          {/* 3.1 Objective */}
          <div>
            <h3 className="subheaders-font-style">Objective</h3>
            <p>
              The Challenge aims to develop end-to-end classification techniques
              for identifying harmful memes containing social bias, a subset of
              harmful online content. This focus is intended to address the
              nuanced ways social biases can manifest and propagate through
              memes.
            </p>
          </div>
          <p>&nbsp;</p>
          {/* 3.2 Definitions */}
          <div>
            <h3 className="subheaders-font-style">Definitions</h3>
            <p>
              The Online Safety space is fast evolving. For the Challenge, it is
              useful to define a few key concepts that are associated with the
              task.{" "}
            </p>

            <p>
              <b>Harmful Online Content: </b>
              Refers to any form of digital content that can cause
              psychological, reputational, or physical harm to individuals or
              groups. This includes content that promotes hate, violence,
              self-harm, discrimination, or misinformation. Harmful online
              content can have significant real-world impacts, from influencing
              public opinion to inciting real-world actions.
            </p>

            <p>
              <b>Memes: </b>
              Digital content, often image-based with accompanying text, used to
              express ideas, humour, or commentary. Memes are a potent tool for
              cultural expression but can also be used to spread harmful
              content. For this Challenge, the scope is confined to static image
              memes. This specification ensures a standardised format for
              submissions and evaluation, focusing the Challenge on consistent
              digital content.
            </p>

            <p>
              <b>Prejudice: </b>
              An unfair and unreasonable opinion or feeling, especially when
              formed without enough thought or knowledge (Cambridge Dictionary).
            </p>

            <p>
              <b>Social Bias: </b>
              Prejudice towards certain groups based on social characteristics,
              such as age, disabilities, gender, nationality, religion, race,
              socioeconomic status, and sexual orientation.
            </p>
          </div>
          <p>&nbsp;</p>
          {/* 3.3 Challenge Focus */}
          <div>
            <h3 className="subheaders-font-style">
              Challenge Focus - Harmful Memes with Social Bias
            </h3>
            <p>
              Instead of encompassing the broader range of harmful online
              content, such as general misinformation, explicit violence, or
              content promoting self-harm, the Challenge is exclusively focused
              on detecting and classifying memes that contain the following
              social biases or otherwise portraying in a negative light the
              following categories:
            </p>
            <ul>
              <li>
                <b>Racial Disparities: </b>
                Memes perpetuating stereotypes or prejudices based on race or
                ethnicity.
              </li>
              <li>
                <b>Religious Beliefs and Practices: </b>
                Memes that mock or demean specific religions or religious
                practices.
              </li>
              <li>
                <b>Sexual Orientation: </b>
                Memes that promotes negative stereotypes or biases about
                different sexual orientations.
              </li>
              <li>
                <b>Nationalistic Sentiments and Xenophobia: </b>
                Memes that foster negative attitudes towards migrants or glorify
                extreme nationalistic views.
              </li>
              <li>
                <b>Socio Economic Divides: </b>
                Memes that highlights or ridicules class struggles and
                socio-economic disparities.
              </li>
              <li>
                <b>Age-Related Biases: </b>
                Memes perpetuating stereotypes or biases based on a person's
                age.
              </li>
              <li>
                <b>Gender Discrimination: </b>
                Memes that promotes gender stereotypes or discriminates based on
                gender.
              </li>
              <li>
                <b>Discrimination Based on Illnesses and Disabilities: </b>
                Memes that mock or belittle individuals with illnesses or
                disabilities.
              </li>
            </ul>
            <p>
              These social biases can perpetuate or amplify prejudices against
              specific groups or individuals, and potentially contribute to
              societal divisions and discrimination through the subtle or overt
              promotion of stereotypes and biased narratives.{" "}
            </p>
          </div>
        </CustomAccordion>

        {/* <CustomAccordion
          testId="challenge-supplementary-information"
          title="Challenge Supplementary Information"
        ></CustomAccordion> */}

        {/* 4. References */}
        <CustomAccordion testId="challenge-references" title="References">
          <ol>
            <li>
              Meta. Hateful Memes Challenge and dataset for research on harmful
              multimodal content 2020, May 12, 2020 [Available from:
              https://ai.meta.com/blog/hateful-memes-challenge-and-data-set]
            </li>
            <li>
              Cooperman A, Hackett C, Ritchey K. Global Religious Diversity:
              Half of the Most Religiously Diverse Countries are in Asia-Pacific
              Region. Pew Research Center; 2014. URL:
              https://www.pewresearch.org/religion/2014/04/04/global-religious-diversity
            </li>
            <li>
              Ho G, Kurohi R. High awareness of race and religion sensitivities
              but fault lines on class, immigration and LGBTQ issues: IPS study.
              The Straits Times. 2019.
              https://www.straitstimes.com/singapore/majority-of-singaporeans-uncomfortable-with-religious-leaders-speaking-up-on-lgbt-issues
            </li>
            <li>
              Sharma S, Alam F, Akhtar MS, Dimitrov D, Martino GDS, Firooz H, et
              al. Detecting and understanding harmful memes: A survey.
              Proceedings of the Thirty-First International Joint Conference on
              Artiﬁcial Intelligence (IJCAI-22) - Survey Track, Macao, China,
              August 2023. https://www.ijcai.org/proceedings/2022/0781.pdf
            </li>
            <li>
              Prakash N, Hee MS, Lee RK-W. TotalDefMeme: A Multi-Attribute Meme
              dataset on Total Defence in Singapore. Proceedings of the 14th
              Conference on ACM Multimedia Systems, Vancouver, Canada, June
              2023. https://doi.org/10.1145/3587819.3592545
            </li>
            <li>
              Velioglu R, Rose J. Detecting hate speech in memes using
              multimodal deep learning approaches: Prize-winning solution to
              hateful memes challenge. arXiv preprint:
              https://doi.org/10.48550/arXiv.2012.12975
            </li>
            <li>
              Corrado G and Matias, Y. Multimodal medical AI: Google Research;
              2023 [Available from:
              https://blog.research.google/2023/08/multimodal-medical-ai.html]
            </li>
            <li>
              Cao R, Lee RK-W, Chong W-H, Jiang J. Prompting for multimodal
              hateful meme classification. Conference on Empirical Methods in
              Natural Language Processing (EMNLP), Abu Dhabi, UAE 12/2022.
              https://aclanthology.org/2022.emnlp-main.22.pdf
            </li>
          </ol>
        </CustomAccordion>
      </div>
    </div>
  );
};

export default Background;
