const datetimeConverter = (datetimeString, defaultTimezone = false) => {
  const utcDatetime = new Date(datetimeString);
  let userDatetime;

  if (defaultTimezone) {
    // if defaultTimezone, use database record's original timezone
    userDatetime = utcDatetime;
  } else {
    // else, get user's browser location timezone
    userDatetime = new Date(
      utcDatetime.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  }

  // Get year, month, and day
  const year = userDatetime.getFullYear();
  const month = String(userDatetime.getMonth() + 1).padStart(2, "0");
  const day = String(userDatetime.getDate()).padStart(2, "0");

  // Get hours, minutes, and seconds
  const hours = String(userDatetime.getHours()).padStart(2, "0");
  const minutes = String(userDatetime.getMinutes()).padStart(2, "0");
  const seconds = String(userDatetime.getSeconds()).padStart(2, "0");

  // Get timezone offset
  const timezoneOffset = userDatetime.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
  const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
  const timezoneSign = timezoneOffset >= 0 ? "-" : "+";
  const timezone = `${timezoneSign}${String(timezoneOffsetHours).padStart(
    2,
    "0"
  )}:${String(timezoneOffsetMinutes).padStart(2, "0")}`;

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds} (GMT${timezone})`;
};
export default datetimeConverter;
