import React from "react";
import { Table } from "react-bootstrap";
import CustomAccordion from "../../shared/Accordion/Accordion";

const PrizesAndTimeline = () => {
  return (
    <div data-testid="prizes-wrapper" className="wrapper">
      <CustomAccordion testId="prize-title" title="Prizes and Timeline">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
          pellentesque velit vel velit dignissim, vel malesuada nibh bibendum.
        </p>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Prize</th>
              <th>Timeline</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>$1000</td>
              <td>January 1, 2022 - March 31, 2022</td>
            </tr>
            <tr>
              <td>2</td>
              <td>$500</td>
              <td>April 1, 2022 - June 30, 2022</td>
            </tr>
            {/* Add more prize and timeline entries here */}
          </tbody>
        </Table>
      </CustomAccordion>
    </div>
  );
};

export default PrizesAndTimeline;
