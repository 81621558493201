import React from "react";
import datetimeConverter from "utils/datetimeConverter";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import staticFinalLeaderboard from "components/Results/staticFinalLeaderboard.json";

import {
  PUBLIC_BENCHMARK_RECORDS,
  sortedRecords,
  BenchmarkRecordRow,
} from "components/Results/BenchmarkRecords";

import "./PublicLeaderboard.css";

const PublicLeaderboard = () => {

  function EnhancedTableHead(props) {
    return (
      <TableHead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          width: "100%",
          backgroundColor: "#F2F2F2",
        }}
      >
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: "100",
            }}
          >
            <TableHead>Ranks</TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
              width: "300",
            }}
          >
            <TableHead>Team Name + Total Submissions</TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
            }}
          >
            <TableHead>AUROC</TableHead>
          </TableCell>

          <TableCell
            sx={{
              fontWeight: "bold",
              border: "2px solid black",
            }}
          >
            <TableHead>Accuracy</TableHead>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableToolbar(props) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%", textAlign: "left" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <b>Public Leaderboard</b>
        </Typography>
      </Toolbar>
    );
  }

  // EnhancedTableToolbar.propTypes = {
  //   numSelected: PropTypes.number.isRequired,
  // };

  // const [selected, setSelected] = React.useState([]);

  const renderTable = () => {
    return (
      <Box sx={{ paddingBottom: "20px" }}>
        <Paper>
          <EnhancedTableToolbar />

          <TableContainer
            style={{
              overflowX: "initial",
            }}
          >
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead />
              <TableBody>
                {results?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (!row.team_id) {
                    return (
                      <BenchmarkRecordRow
                        row={row}
                        leaderboardType={"public"}
                      />
                    );
                  }

                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.team_id}>
                      <TableCell
                        id={labelId}
                        style={{
                          border: "2px solid black",
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {row.ranking}
                        </div>
                      </TableCell>
                      <TableCell
                        id={labelId}
                        style={{
                          border: "2px solid black",
                        }}
                      >
                        <div
                          style={{
                            width: 450,
                            paddingTop: "10px",
                          }}
                        >
                          <div style={{ color: "#ae2514" }}>
                            <b>{row.team_name}</b>
                          </div>

                          <div
                            style={{
                              color: "grey",
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "5px",
                            }}
                          >
                            <div>
                              {row.successful_attempts}
                              {" Submission(s)"}
                            </div>
                            <div>
                              Best public score submission on:{" "}
                              {/* {row.created_date.split("T")[0]} */}
                              {datetimeConverter(row.created_date)}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid black",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.score_public_auroc}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid black",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3px",
                          }}
                        >
                          {row.score_public_accuracy}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  let leaderboard = staticFinalLeaderboard;
  let results = null;
  if (leaderboard["results"]) {
    if (PUBLIC_BENCHMARK_RECORDS && PUBLIC_BENCHMARK_RECORDS.length > 0) {
      results = sortedRecords(
        [...PUBLIC_BENCHMARK_RECORDS, ...leaderboard["results"]],
        "score_public_auroc",
        "score_public_accuracy"
      );
    } else {
      results = leaderboard["results"];
    }
  }

  return (
    <div className="pr-container">
      <div>{renderTable()}</div>
    </div>
  );
};

export default PublicLeaderboard;
