import React from "react";
import CustomAccordion from "shared/Accordion/Accordion";
import "./Background.css";
import scoring_contribution_table from "assets/Faq/scoring_contribution_table.png";
import prizes_table from "assets/Faq/prizes_table.png";

const ChallengeStructure = () => {
  return (
    <div data-testid="challenge-structure-wrapper" className="wrapper">
      <div data-testid="the-title" className="the-title">
        Challenge Structure
      </div>

      {/* 1. OVERVIEW */}
      <CustomAccordion
        testId="challenge-structure-wrapper-overview"
        title="Overview"
      >
        <p>
          This Challenge is a 10-week competition aimed to encourage
          participants to develop a multi-modal classification model that can
          differentiate between benign and harmful memes containing social bias.
          Due to the sensitive nature of the content, no datasets will be
          provided for model development. Participants will be required to
          source for relevant datasets, devise data augmentation methods, or
          employ automated meme generation techniques to curate an appropriate
          dataset for model training and testing.
        </p>
      </CustomAccordion>

      {/* 2. TIMELINE */}
      <CustomAccordion testId="challenge-structure-timeline" title="Timeline">
        <p>The following are the key dates for the Challenge:</p>
        <div>
          <ul>
            <li>
              Challenge Start
              Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;31
              January 2024 12:00 PM SGT (GMT+8)
            </li>
            <li>
              Launch Of Submission
              Portal:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14
              February 2024 12:00 PM SGT (GMT+8)
            </li>
            <li>
              Deadline For Team
              Formation:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20
              March 2024 12:00 PM SGT (GMT+8)
            </li>
            <li>
              Deadline For
              Submission:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11
              April 2024 12:00 PM SGT (GMT+8)
            </li>
            <li>
              Presentation & Award
              Ceremony:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13
              May 2024
            </li>
          </ul>
        </div>
      </CustomAccordion>

      {/* 3. EVALUATION METRICS */}
      <CustomAccordion
        testId="challenge-structure-submissions-and-deliverables"
        title="Submissions and Deliverables"
      >
        <p>
          The submission portal will only be available from 14 February 2024 at
          12:00 PM SGT (GMT+8). During the initial two-week phase, participants
          are expected to focus on foundational activities including team
          formation, exploration of appropriate methodologies, and in-depth
          analysis of the relevant socio-linguistic context. This period also
          encompasses the commencement of data collection and augmentation,
          along with the system architecture development, in compliance with the
          established submission guidelines.
        </p>
        <p>
          Upon activation of the submission portal, participants will be
          permitted a maximum of seven submissions over a rolling two-week
          period for the duration of the Challenge. Each submission should
          consist of a single Docker container which will be evaluated against a
          hidden evaluation dataset, and the results will be made public on our
          leaderboard. This is intended to provide participants with the
          feedback required to optimize their model further.
        </p>
      </CustomAccordion>

      {/* 4. EVALUATION METRICS */}
      <CustomAccordion
        testId="challenge-structure-evaluation-metrics"
        title="Evaluation Metrics"
      >
        <p>
          The primary mode of evaluation for the Challenge will be the Area
          Under the Curve of the Receiving Operator Characteristic (AUROC). It
          is important to note that a team's ranking on the leaderboard is not
          the sole determinant for prize eligibility.
        </p>
        <p>
          The top five teams with the highest scores, as ranked on the
          leaderboard, will be selected as finalists. All datasets and code
          bases of the five finalists that contributed to the development of the
          final model must be submitted along with a 4-page technical report
          (including references) for further verification and evaluation by a
          panel of judges (Technical Review Committee). The Technical Review
          Committee will assess each submission for novelty & innovativeness,
          code quality & readability, and reproducibility of the methods used.
        </p>
        <p>
          Additionally, finalists must present their solutions to the Technical
          Review Committee at{" "}
          <a
            href="https://www2024.thewebconf.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="url-text-styling"
          >
            The Web Conference 2024
          </a>
          , taking place in Singapore from 13 – 17 May 2024. This presentation
          is a critical component of the final evaluation. Failure to submit the
          required materials or to present at{" "}
          <a
            href="https://www2024.thewebconf.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="url-text-styling"
          >
            The Web Conference 2024
          </a>{" "}
          will result in disqualification from the Challenge.
        </p>
        <p>
          The winner and runners-up of the Challenge will be determined based on
          a combination of both scores. The contribution of each component is
          displayed in the following table:
        </p>

        <div className="figure-two">
          <img
            className="table-img-size"
            src={scoring_contribution_table}
            alt="scoring_contribution_table"
          ></img>
        </div>
      </CustomAccordion>

      {/* 5. PRIZES */}
      <CustomAccordion testId="challenge-structure-prizes" title="Prizes">
        <p>
          The top three teams will be declared as the winners of the challenge
          and will be awarded with the following cash prizes:{" "}
        </p>

        <div className="figure-two">
          <img
            className="table-img-size"
            src={prizes_table}
            alt="prizes_table"
          ></img>
        </div>
      </CustomAccordion>
    </div>
  );
};

export default ChallengeStructure;
