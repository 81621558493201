import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Define public benchmark records here
// - Public benchmark records are inserted into private leaderboard
const PUBLIC_BENCHMARK_RECORDS = [
  {
    model_name: "VisualBERT",
    score_public_auroc: "0.5561",
    score_public_accuracy: "0.5379",
  },
  {
    model_name: "FLAVA",
    score_public_auroc: "0.5695",
    score_public_accuracy: "0.5529",
  },
];

// Define private benchmark records here
// - Private benchmark records are inserted into private leaderboard
const PRIVATE_BENCHMARK_RECORDS = [
  {
    model_name: "VisualBERT",
    score_private_auroc: "0.5561",
    score_private_accuracy: "0.5379",
    score_public_auroc: "0.5561",
    score_public_accuracy: "0.5379",
  },
  {
    model_name: "FLAVA",
    score_private_auroc: "0.5695",
    score_private_accuracy: "0.5529",
    score_public_auroc: "0.5695",
    score_public_accuracy: "0.5529",
  },
];

const sortedRecords = (records, score_1, score_2) => {
  /* 
    Sort the leaderboard records based on score_1, followed by score_2.
  */
  const sorted = records.sort((a, b) => {
    const aucComparison = parseFloat(b[score_1]) - parseFloat(a[score_1]);
    return aucComparison !== 0
      ? aucComparison
      : parseFloat(b[score_2]) - parseFloat(a[score_2]);
  });

  return sorted;
};

const BenchmarkRecordRow = (props) => {
  /* 
    Function that renders the row of a benchmark ML model record on the leaderboard.
  */
  const { row, leaderboardType } = props;
  const benchmarkRowBackgroundColor = "#fdeadc";

  if (leaderboardType === "public") {
    // FOR PUBLIC LEADERBOARD: benchmark model's row component for public leaderboard (styling should align with public leaderboard)
    return (
      <TableRow
        key={row.model_name}
        style={{ backgroundColor: benchmarkRowBackgroundColor }}
      >
        <TableCell
          colSpan={1}
          style={{
            border: "2px solid black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            -
          </div>
        </TableCell>
        <TableCell
          colSpan={1}
          style={{
            border: "2px solid black",
          }}
        >
          <div>
            <b>Benchmark: </b>
            {row.model_name}
          </div>
        </TableCell>
        <TableCell
          colSpan={1}
          style={{
            border: "2px solid black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_public_auroc}
          </div>
        </TableCell>
        <TableCell
          colSpan={1}
          style={{
            border: "2px solid black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_public_accuracy}
          </div>
        </TableCell>
      </TableRow>
    );
  } else if (leaderboardType === "private") {
    // FOR PRIVATE LEADERBOARD: benchmark model's row component for private leaderboard (styling should align with private leaderboard)
    return (
      <TableRow
        key={row.model_name}
        style={{ backgroundColor: benchmarkRowBackgroundColor }}
      >
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            -
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div>
            <b>Benchmark: </b>
            {row.model_name}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_private_auroc}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_private_accuracy}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_public_auroc}
          </div>
        </TableCell>
        <TableCell colSpan={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3px",
            }}
          >
            {row.score_public_accuracy}
          </div>
        </TableCell>
      </TableRow>
    );
  }
};

export {
  //   convertStringToFloat,
  PUBLIC_BENCHMARK_RECORDS,
  PRIVATE_BENCHMARK_RECORDS,
  sortedRecords,
  BenchmarkRecordRow,
};
