import React from "react";
import CustomAccordion from "shared/Accordion/Accordion";
import "./Background.css";

const TechnicalDetails = () => {
  const sampleSubmissionGithubUrl =
    "https://github.com/AISG-Technology-Team/AISG-Online-Safety-Challenge-Submission-Guide";
  const discussionBoardGithubUrl =
    "https://github.com/AISG-Technology-Team/AISG-Online-Safety-Challenge-Submission-Guide/issues";

  return (
    <div data-testid="technical-details-wrapper" className="wrapper">
      <div data-testid="the-title" className="the-title">
        Technical Details
      </div>

      {/* 1. Guidelines on Model & Docker Container Formats */}
      <CustomAccordion
        testId="disclaimer-title"
        title="Guidelines on Model & Docker Container Formats"
      >
        <ol>
          <li>
            The complete submission guidelines containing the required
            input-output formats, runtime, and size specifications for the
            Docker container are available at{" "}
            <a
              href={sampleSubmissionGithubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {sampleSubmissionGithubUrl}
            </a>
            . Participants are strongly encouraged to review these
            specifications in detail to ensure their submissions are processed
            and scored correctly.{" "}
          </li>
          <li>
            For consistency in model input processing, all image data in the
            evaluation dataset will be pre-processed into PNG format.
            Participants must calibrate their models to be compatible with PNG
            file inputs.{" "}
          </li>
          <li>
            Participants are advised that there are predefined limits on the
            Docker containers. These limits are specified in the submission
            guidelines linked above. These limitations are in place to ensure
            equitable computational resource usage and to maintain performance
            efficiency across all submissions.{" "}
          </li>
          <li>
            All essential resources for the model's inference phase must be
            encapsulated within the Docker container. Please note that during
            runtime, the Docker container will be isolated without internet
            connectivity or the ability to access external resources or data.
            This isolation ensures a secure and controlled execution environment
            for the evaluation.{" "}
          </li>
          <li>
            All software and tools in the Docker container and the entire
            development toolchain must be open source, ensuring transparency,
            unrestricted auditing, and collaboration. Proprietary software or
            code is strictly prohibited to maintain the principles of open
            innovation, allowing free access and improvement by the community.{" "}
          </li>
        </ol>
      </CustomAccordion>

      {/* 2. Leaderboard Ranking */}
      <CustomAccordion
        testId="disclaimer-title"
        title="Leaderboard and Ranking"
      >
        <ol>
          <li>
            Submissions will be evaluated against a hidden evaluation dataset.
            AUROC and accuracy scores will be calculated for each submission,
            and these will be displayed on a leaderboard. Teams will be ranked
            primarily by AUROC scores.
          </li>
          <li>
            In the event of a tie, model accuracy will be used as a secondary
            metric to determine leaderboard ranking. The accuracy of both teams’
            top performing model based on AUROC on the leaderboard will be
            compared against each other.
          </li>
        </ol>
      </CustomAccordion>

      {/* 3. Prizes and Conditions */}
      <CustomAccordion testId="disclaimer-title" title="Prizes and Conditions">
        <ol>
          <li>
            The top five teams ranked on the leaderboard will be selected as
            finalists and invited to submit their complete code bases, as well
            as a 4-page technical report (including references) detailing the
            end-to-end solution. The Technical Review Committee will evaluate
            the technical solution and respective code bases for their novelty &
            innovativeness, code quality & readability, as well as
            reproducibility. Additionally, the technical report submitted by the
            finalists must be written according to the{" "}
            <a
              href="https://www.acm.org/publications/proceedings-template"
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              ACM submission guidelines
            </a>
            . If the technical reports are found to be of sufficient quality,
            they will be eligible for publication in the proceedings of the
            conference, subject to the finalist's consent.
          </li>
          <li>
            In adherence to the principles of transparency and reproducibility
            in model training, all datasets utilised during the model training
            process must be submitted concurrently with the corresponding
            codebases. Reproducibility of the solution is a graded component in
            the evaluation and ensures a comprehensive review of the resources
            used within the development lifecycle. A detailed account of these
            datasets must be provided in instances where specific dataset
            segments are confidential or cannot be disclosed due to commercial
            sensitivity or proprietary constraints. This account must include
            the source, volume, and a comprehensive structured description to
            enable AISG to accurately ascertain the origin of the data in
            question.
          </li>
          <li>
            By submitting the datasets as part of the evaluation submission
            process, participants implicitly affirm that they possess the
            requisite authorisation to employ the datasets in the manner
            prescribed. The participant must ensure that all rights and usage
            compliances concerning the datasets are duly observed. Participants
            will be required to certify in writing that they have permission to
            use the datasets as part of their solutions. AISG bears no liability
            for any form of data misuse that may transpire due to actions taken
            by the participant.{" "}
          </li>
          <li>
            As part of the evaluation by the Technical Review Committee, the
            finalists will be required to present their solutions in-person at{" "}
            <a
              href="https://www2024.thewebconf.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              The Web Conference 2024
            </a>
            , held in Singapore from 13 – 17 May 2024. The team must present
            their comprehensive solutions during a 15-minute session followed by
            a 5-minute question and answer segment. This presentation should
            encapsulate the entirety of the project scope, encompassing data
            collection, model selection and training protocols, iterative
            developments, and any additional innovative processes as highlighted
            by the Technical Review Committee.
          </li>

          <li>
            While the winning solutions will remain the intellectual property of
            the originating team, to qualify for accolades and any associated
            prize disbursements, the participants shall grant AISG a
            non-exclusive, perpetual, royalty-free, non-transferable,
            sublicensable right for to use the winning solutions for research,
            academic and non-commercial use.
          </li>

          <li>
            The complete Challenge Terms & Conditions can be found at the
            following link{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              https://ospc.aisingapore.org/terms-and-conditions
            </a>
            . Participants are strongly encouraged to review these terms and
            conditions in detail to ensure full compliance and understanding of
            the Challenge rules.
          </li>

          <li>
            To address further queries, a dedicated discussion board will be
            established for participant inquiries. Technical issues or questions
            directly related to the Challenge should be submitted through this
            platform. Participants can access the discussion board via this link{" "}
            <a
              href={discussionBoardGithubUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {discussionBoardGithubUrl}
            </a>
            . To post questions or participate in discussions, a Github account
            is required. This measure ensures a secure and authenticated user
            experience. Please read the instructions for posting questions here{" "}
            <a
              href={discussionBoardGithubUrl + "/1"}
              target="_blank"
              rel="noopener noreferrer"
              className="url-text-styling"
            >
              {discussionBoardGithubUrl + "/1"}
            </a>{" "}
            before posting questions to the discussion board. Our support team
            is committed to responding within three business days, excluding
            weekends and public holidays. In the event of a high volume of
            inquiries or unforeseen circumstances, response times may be
            extended. We appreciate your understanding and patience in these
            situations.
          </li>
          <li>
            The discussion board is intended explicitly for technical issues and
            questions related to the Challenge. Queries outside this scope, such
            as general inquiries about program policies or administrative
            matters, should be directed to our support email at{" "}
            <a
              href="mailto:prizechallenge@aisingapore.org"
              className="url-text-styling"
            >
              prizechallenge@aisingapore.org
            </a>
            .  
          </li>
        </ol>
      </CustomAccordion>
    </div>
  );
};

export default TechnicalDetails;
