import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import "./AppNavigator.css";
import Logo from "assets/ailogowhite.png";
import { FaChevronDown } from "react-icons/fa";

// page components
import {
  ChallengeInformation,
  ChallengeStructure,
  TechnicalDetails,
  Background,
  PrizesAndTimeline,
  TermsAndConditions,
  SupplementaryInformation,
} from "components/Faq";

import PublicLeaderboard from "components/Results/PublicLeaderboard/PublicLeaderboard";

const AppNavigator = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isChallengeExpanded, setIsChallengeExpanded] = useState(false);

  // // Handle click event for the link
  // const triggerReloadIfOnSamePath = (targetURL) => {
  //   /*
  //    * Function to trigger a page reload if user is on the same path as the navbar item's path that they click on.
  //    * E.g. if user is on the path '/submit_create' and they click 'Submissions' in the navbar, it will trigger a reload of the page.
  //    * This reload is mainly used to check that the JWT token still exist/valid and log the user out if it doesn't.
  //    */
  //   // Check if the link is already active
  //   if (window.location.pathname === targetURL) {
  //     // Navigate to the new path to force a re-mount of the component
  //     window.location.href = targetURL;
  //   }
  //   return;
  // };

  const challengeDetailsDropdownComponents = () => {
    return (
      <>
        <Link to="/">Challenge Information</Link>
        <Link to="/background">Background</Link>
        <Link to="/challenge-structure">Challenge Structure</Link>
        <Link to="/technical-details">Technical Details</Link>
        <Link to="/supplementary-information">Supplementary Information</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </>
    );
  };

  return (
    <div className="the-big-container">
      <Router>
        <nav className="nav-styling">
          <Toaster />
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <img className="nav-image-styling" src={Logo} alt="ailogo"></img>
          </Link>

          <button
            className="hamburger"
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          {/* Challenge Details Dropdown (Hamburger Version) */}
          {isNavExpanded && (
            <div className="nav-burger">
              <Link to="/" className="nav-link-styling-ham">
                <ul className="dropdown-ham">
                  <a
                    href="#"
                    className="drpbtn-ham"
                    onClick={() => {
                      setIsChallengeExpanded(!isChallengeExpanded);
                    }}
                  >
                    Challenge Details
                  </a>
                  <span style={{ marginLeft: "0.625rem" }}>
                    <FaChevronDown />
                  </span>
                  {isChallengeExpanded && (
                    <div className="drpmenu-ham">
                      {challengeDetailsDropdownComponents()}
                    </div>
                  )}
                </ul>
              </Link>
              <Link
                to="/leaderboard"
                className="nav-link-styling-ham"
              >
                Public Leaderboard
              </Link>
            </div>
          )}

          {/* Challenge Details Dropdown */}
          <Link to="/" className="nav-link-styling">
            <ul className="dropdown">
              <a href="#" className="drpbtn">
                Challenge Details
              </a>
              <span style={{ marginLeft: "0.625rem" }}>
                <FaChevronDown />
              </span>
              <div className="drpmenu">
                {challengeDetailsDropdownComponents()}
              </div>
            </ul>
          </Link>
          <Link
            to="/leaderboard"
            className="nav-link-styling"
          >
            Public Leaderboard
          </Link>
        </nav>

        <Routes>
          <Route exact path="/" element={<ChallengeInformation />} />
          <Route exact path="/background" element={<Background />} />
          <Route
            exact
            path="/technical-details"
            element={<TechnicalDetails />}
          />
          <Route
            exact
            path="/challenge-structure"
            element={<ChallengeStructure />}
          />
          <Route exact path="/pnt" element={<PrizesAndTimeline />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            exact
            path="/supplementary-information"
            element={<SupplementaryInformation />}
          />
          <Route exact path="/leaderboard" element={<PublicLeaderboard />} />

          {/* redirect user to ChallengeInformation page if they enter an invalid url */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* </ErrorBoundary> */}
      </Router>
    </div>
  );
};

export default AppNavigator;
